<template>
  <div class="relative z-50">
    <header class="header relative">
          <div class="header-1 mx-auto text-center">
            <div class="absolute top-0 left-1/2 -translate-x-1/2">
              <!-- <i class="fas  text-4xl text-[#ff2e63] lantern-glow"></i> -->
            </div>
            <h1 class="text-4xl md:text-6xl font-bold text-white mb-6 font-bubblegum-sans main-title">
              Join Batbaby
            </h1>
            <p class="text-[#ff2e63] text-xl md:text-2xl font-comic-neue gradient-text">
              Batbaby is a Web3-native trendsetting IP presented in high-quality 3D-designed NFTs.
            </p>
          </div>
        </header>

  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
     
    };
  },
  methods: {
    
  },
  mounted() {
   
  },
  beforeDestroy() {
   
  },
};
</script>

<style scoped>
  .header{
    padding-top: 6rem;
    padding-bottom: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    .header-1{
      max-width: 80rem;

    }
  }
 .z-50 {
    z-index: 50;
  }


@media (max-width: 768px) {
  
}
</style>