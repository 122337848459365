<template>
   
   <section className="max-w-7xl mx-auto mb-20 timeline-section">
  <h2 className="text-center text-4xl md:text-5xl font-bubblegum-sans text-[#6c63ff] mb-16 roadmap-title">
    发展路线图
    <i className="fas fa-sparkles ml-3 text-[#ff2e63]"></i>
  </h2>
  <div className="relative">
    <div className="hidden md:block absolute top-1/2 left-0 w-full">
      <svg className="w-full h-24 timeline-path" viewBox="0 0 1200 100" preserveAspectRatio="none">
        <path d="M0,50 C300,100 600,0 900,100 L1200,50" className="stroke-[3] stroke-[#6c63ff] fill-none path-animation glow-path" />
      </svg>
      <div className="absolute top-1/2 left-0 w-full h-full overflow-hidden">
        <div className="timeline-dots"></div>
      </div>
    </div>

    <div className="space-y-24 md:space-y-0 fade-in">

      <div className="flex flex-col md:flex-row items-center md:gap-8 timeline-item">
        <div className="w-full md:w-1/2">
          <div className="roadmap-card p-6 rounded-xl border border-[#6c63ff] hover:border-[#ff2e63] bg-[#1a1a2e]/80 relative">
          
            <div className="absolute -top-6 left-1/2 md:left-0 transform -translate-x-1/2 md:translate-x-0 bg-[#1a1a2e] px-6 py-2 rounded-full border-2 border-[#6c63ff] timeline-date z-10">
              <span className="text-[#6c63ff] font-bold text-lg">2025 Q1</span>
            </div>
            <div className="space-y-4 mt-4">
              <i className="fas fa-coins text-[#6c63ff] text-3xl"></i>
              <h3 className="text-[#ff2e63] font-bold text-xl">完成创世系列 NFT 发行</h3>
              <p className="text-gray-300">建立初始社区治理架构</p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 p-4">
          <img src="../assets/imgs/babybat1.png" alt="NFT Creation and Community Building" className="w-full h-auto max-w-md mx-auto hover-float" />
        </div>
      </div>


      <div className="flex flex-col md:flex-row-reverse items-center md:gap-8 timeline-item">
        <div className="w-full md:w-1/2">
          <div className="roadmap-card p-6 rounded-xl border border-[#6c63ff] hover:border-[#ff2e63] bg-[#1a1a2e]/80 relative">
         
            <div className="absolute -top-6 left-1/2 md:right-0 transform -translate-x-1/2 md:translate-x-0 bg-[#1a1a2e] px-6 py-2 rounded-full border-2 border-[#6c63ff] timeline-date z-10">
              <span className="text-[#6c63ff] font-bold text-lg">2025 Q2</span>
            </div>
            <div className="space-y-4 mt-4">
              <i className="fas fa-handshake text-[#6c63ff] text-3xl"></i>
              <h3 className="text-[#ff2e63] font-bold text-xl">启动品牌合作并发布特别版 NFT</h3>
              <p className="text-gray-300">开发 BatX 质押机制和 NFT 持有者激励系统</p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 p-4">
          <img src="../assets/imgs/babyone.png" alt="Brand Collaboration and Partnerships" className="w-full h-auto max-w-md mx-auto hover-float" />
        </div>
      </div>

   
      <div className="flex flex-col md:flex-row items-center md:gap-8 timeline-item">
        <div className="w-full md:w-1/2">
          <div className="roadmap-card p-6 rounded-xl border border-[#6c63ff] hover:border-[#ff2e63] bg-[#1a1a2e]/80 relative glow-card">
           
            <div className="absolute -top-6 left-1/2 md:left-0 transform -translate-x-1/2 md:translate-x-0 bg-[#1a1a2e] px-6 py-2 rounded-full border-2 border-[#6c63ff] timeline-date z-10">
              <span className="text-[#6c63ff] font-bold text-lg">2025 Q3-Q4</span>
            </div>
            <div className="space-y-4 mt-4">
              <i className="fas fa-gamepad text-[#6c63ff] text-3xl"></i>
              <h3 className="text-[#ff2e63] font-bold text-xl">引入 GameFi 互动体验</h3>
              <p className="text-gray-300">部署 Batbaby 专属元宇宙功能</p>
              <p className="text-gray-300">通过品牌衍生品和 IP 合作扩展生态系统</p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 p-4">
          <img src="../assets/imgs/coolbaby.png" alt="GameFi and Metaverse Experience" className="w-full h-auto max-w-md mx-auto hover-float" />
        </div>
      </div>
    </div>
  </div>
</section>
</template>

<script>
export default {
name: 'Header',
data() {
  return {
   
  };
},
methods: {
  
},
mounted() {
 
},
beforeDestroy() {
 
},
};
</script>

<style scoped>
.about{
  
}



@media (max-width: 768px) {

}
</style>