<template>
  <footer className="bg-[#1a1a2e]/70 py-8 px-4 z-50">
            <div className="max-w-7xl mx-auto text-center">
              <div className="flex justify-center gap-6 mb-6">
                <i className="fas fa-star text-[#6c63ff] text-2xl hover:text-[#ff2e63] transition-colors duration-300"></i>
                <i className="fas fa-moon text-[#6c63ff] text-2xl hover:text-[#ff2e63] transition-colors duration-300"></i>
                <i className="fas fa-sun text-[#6c63ff] text-2xl hover:text-[#ff2e63] transition-colors duration-300"></i>
              </div>
              <p className="text-gray-400" style="font-size: 1.2rem;">
                © 2025 BabyBat. All rights enchanted.
              </p>
            </div>
          </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      
      // 社交媒体链接数据
      socialLinks: [
        
      ],
    };
  },
  computed: {
    // 获取当前年份
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style scoped>
.py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.max-w-7xl {
    max-width: 80rem;
}
.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}
.text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.bg-\[\#1a1a2e\]\/70 {
    --tw-bg-opacity: 0.7;
    background-color: rgba(26, 26, 46, var(--tw-bg-opacity));
}
@media (max-width: 768px) {
  
}
</style>