<template>
   
        <section className="max-w-7xl mx-auto mb-20">
            <div className="bg-[#1a1a2e]/50 backdrop-blur-md rounded-xl p-8 border border-[#6c63ff]/20">
              <h2 className="text-4xl md:text-5xl font-bubblegum-sans text-[#6c63ff] mb-8">
                About Babybat
                <i className="fas fa-wand-sparkles ml-3 text-[#ff2e63]"></i>
              </h2>

              <div className="grid md:grid-cols-2 gap-8 items-center">
                <div className="space-y-6">
                  <p className="text-xl text-gray-300">
                    欢迎来到BatBoy的奇幻世界！我们正在打造一个独特的NFT生态系统，将艺术、游戏和社区完美融合。
                  </p>

                  <div className="space-y-4">
                    <div className="flex items-start">
                      <i className="fas fa-gem text-[#ff2e63] mt-1 mr-4"></i>
                      <p className="text-gray-300">
                        独特的数字艺术：每个NFT都是独一无二的艺术品，承载着神秘的魔法力量。
                      </p>
                    </div>

                    <div className="flex items-start">
                      <i className="fas fa-users text-[#ff2e63] mt-1 mr-4"></i>
                      <p className="text-gray-300">
                        强大的社区：加入我们充满创造力的社区，共同探索无限可能。
                      </p>
                    </div>

                    <div className="flex items-start">
                      <i className="fas fa-gamepad text-[#ff2e63] mt-1 mr-4"></i>
                      <p className="text-gray-300">
                        互动游戏体验：即将推出的GameFi系统，让每个NFT都成为游戏中的重要资产。
                      </p>
                    </div>
                  </div>

                  <div className="flex gap-4">
                    <button className="bg-[#ff2e63] hover:bg-[#ff2e63]/80 text-white px-6 py-3 rounded-full font-bold transition-all">
                      了解更多
                    </button>
                    <button className="border border-[#6c63ff] hover:border-[#ff2e63] text-white px-6 py-3 rounded-full font-bold transition-all">
                      加入社区
                    </button>
                  </div>
                </div>

                <div className="relative" >
                  <div className="aspect-square rounded-xl overflow-hidden border-2 border-[#6c63ff] glow-card">
                    <img
                      src="../assets/imgs/Babybat.png"
                      alt=""
                      className="w-full h-full object-cover hover:scale-110 transition-transform duration-500"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
  </template>
  
  <script>
  export default {
    name: 'Header',
    data() {
      return {
       
      };
    },
    methods: {
      
    },
    mounted() {
     
    },
    beforeDestroy() {
     
    },
  };
  </script>
  
  <style scoped>
    .about{
      
    }
   
  
  
  @media (max-width: 768px) {
    
  }
  </style>