<template>
  <div id="app">
    <div class="gradient-overlay"></div>
    <div className="stars-layer fixed inset-0 z-30"></div>
    <div className="stars-layer-2 fixed inset-0 z-30"></div>
    <div className="stars-layer-3 fixed inset-0 z-30"></div>
    <Nav />
    <Header />
    <main className="px-4 py-12 relative z-50">
      <About />
      <Model />
      <Roadmap />
    </main>
    

    <!-- <Content /> -->
    <Footer />
  </div>
</template>

<script>
import Nav from './components/Nav.vue'
import Header from './components/Header.vue'
import About from './components/About.vue';
import Model from './components/Model.vue';
import Roadmap from './components/Roadmap.vue';
// import Content from './components/Content.vue'
import Footer from './components/Footer.vue'

export default {
  components: {
    Nav,
    Header,
    About,
    Model,
    Roadmap,
    // Content,
    Footer
  }
}
</script>

<style>
@import url('./assets/css/style.css');

#app {
  /* font-family: 'Pacifico', cursive; */
  padding: 0;
  margin: 0;
  color: #fff;
  font-size: 12px;
  background-image:  url('./assets/imgs/batbaby-bg.webp');
  background-size: cover;
  background-position: center; 
  background-attachment: fixed; 
  min-height: 100vh;
  overflow-x: hidden;
  position: relative;
  .gradient-overlay {
    position: fixed;
    inset: 0;
    background: linear-gradient(to bottom, #1a1a2e 0%, rgba(26, 26, 46, 0.8) 50%, rgba(26, 26, 46, 0.4) 100%);
    z-index: 20;
  }

  .stars-layer {
    background-image: radial-gradient(1px 1px at 20px 30px, #fff, rgba(0, 0, 0, 0)), radial-gradient(1px 1px at 40px 70px, #fff, rgba(0, 0, 0, 0)), radial-gradient(1px 1px at 50px 160px, #fff, rgba(0, 0, 0, 0)), radial-gradient(1px 1px at 90px 40px, #fff, rgba(0, 0, 0, 0)), radial-gradient(1px 1px at 130px 80px, #fff, rgba(0, 0, 0, 0));
    background-size: 200px 200px;
    animation: moveStars 60s linear infinite;
    opacity: 0.8;
    background-color: transparent;
  }

  .stars-layer-2 {
    background-image: radial-gradient(2px 2px at 25px 35px, #fff, rgba(0, 0, 0, 0)), radial-gradient(2px 2px at 45px 75px, #fff, rgba(0, 0, 0, 0)), radial-gradient(2px 2px at 55px 165px, #fff, rgba(0, 0, 0, 0)), radial-gradient(2px 2px at 95px 45px, #fff, rgba(0, 0, 0, 0)), radial-gradient(2px 2px at 135px 85px, #fff, rgba(0, 0, 0, 0));
    background-size: 300px 300px;
    animation: moveStars 80s linear infinite;
    opacity: 0.6;
    background-color: transparent;
  }

  .stars-layer-3 {
    background-image: radial-gradient(3px 3px at 15px 25px, #fff, rgba(0, 0, 0, 0)), radial-gradient(3px 3px at 35px 65px, #fff, rgba(0, 0, 0, 0)), radial-gradient(3px 3px at 45px 155px, #fff, rgba(0, 0, 0, 0)), radial-gradient(3px 3px at 85px 35px, #fff, rgba(0, 0, 0, 0)), radial-gradient(3px 3px at 125px 75px, #fff, rgba(0, 0, 0, 0));
    background-size: 400px 400px;
    animation: moveStars 100s linear infinite;
    opacity: 0.4;
    background-color: transparent;
  }

  @keyframes moveStars {
    0% { transform: translateY(0); }
    100% { transform: translateY(-1000px); }
  }

  .fixed {
    position: fixed;
  }
  .z-30 {
    z-index: 30;
  }
  .inset-0 {
    inset: 0px;
  }
  .relative {
    position: relative;
  }
  .z-50 {
    z-index: 50;
  }
  .w-full {
    width: 100%;
  }
  .flex {
    display: flex;
  }
  .justify-between {
    justify-content: space-between;
  }
  .items-center {
    align-items: center;
  }
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .font-bold {
    font-weight: 700;
  }
  .font-bubblegum-sans {
    font-family: 'Bubblegum Sans', cursive;
  }
  .fa-classic, .fa-light, .fa-regular, .fa-solid, .fa-thin, .fal, .far, .fas, .fat {
    font-family: "Font Awesome 6 Pro";
  }
  .fa-solid, .fas {
    font-weight: 900;
  }

  .animate-bounce {
    animation: 1s ease 0s infinite normal none running bounce;
  }

  .block  {
    display: block;
  }
  .hidden {
    display: none;
  }
  .flex-col {
    flex-direction: column;
  }
  .backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }
  .text-\[\#fff\]{
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .transition-colors {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  a {
    color: inherit;
    text-decoration: inherit;
  }
  .absolute {
    position: absolute;
  }
  .text-center {
    text-align: center;
  }
  .top-0 {
    top: 0px;
  }
  .left-1\/2 {
    left: 50%;
  }
  .-translate-x-1\/2 {
    --tw-translate-x: calc(50%* -1);
    transform: var(--tw-transform);
  }
  .font-comic-neue {
    font-family: "Comic Neue";
  }





  .text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.text-\[\#ff2e63\] {
    --tw-text-opacity: 1;
    color: rgba(255, 46, 99, var(--tw-text-opacity));
}

.py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.mb-20 {
    margin-bottom: 5rem;
}

.rounded-xl {
    border-radius: 0.75rem;
}

.border {
    border-width: 1px;
}
.max-w-7xl {
    max-width: 80rem;
}


.backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.bg-\[\#1a1a2e\]\/50 {
    --tw-bg-opacity: 0.5;
    background-color: rgba(26, 26, 46, var(--tw-bg-opacity));
}

.border-\[\#6c63ff\]\/20 {
    --tw-border-opacity: 0.2;
    border-color: rgba(108, 99, 255, var(--tw-border-opacity));
}

.p-8 {
    padding: 2rem;
}



  @media (min-width: 768px) {
    .md\:text-6xl {
        font-size: 3.75rem;
        line-height: 1;
    }
    .md\:text-2xl {
        font-size: 1.5rem;
        line-height: 2rem;
    }
  }


  @media (min-width: 1024px) {
    .lg\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0px, 1fr));
    }
}

@media (min-width: 768px) {
    .md\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0px, 1fr));
    }
    .md\:w-1\/2 {
        width: 50%;
    }
    .md\:flex-row {
        flex-direction: row;
    }
    .md\:gap-8 {
        gap: 2rem;
    }
    .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(0px* calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(0px* var(--tw-space-y-reverse));
    }
    .md\:flex-row-reverse {
        flex-direction: row-reverse;
    }
  
}
.space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem* calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem* var(--tw-space-y-reverse));
}

.border {
    border-width: 1px;
}

.border-\[\#6c63ff\] {
    --tw-border-opacity: 1;
    border-color: rgba(108, 99, 255, var(--tw-border-opacity));
}

.bg-\[\#1a1a2e\]\/80 {
    --tw-bg-opacity: 0.8;
    background-color: rgba(26, 26, 46, var(--tw-bg-opacity));
}

.p-6 {
    padding: 1.5rem;
}

rounded-full {
    border-radius: 9999px;
}

.border {
    border-width: 1px;
}

.left-1\/2 {
    left: 50%;
}

.-top-4 {
    top: calc(-1rem);
}

.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.border-\[\#6c63ff\] {
    --tw-border-opacity: 1;
    border-color: rgba(108, 99, 255, var(--tw-border-opacity));
}

.bg-\[\#1a1a2e\] {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 26, 46, var(--tw-bg-opacity));
}

.transform {
    transform: var(--tw-transform);
}

.text-\[\#6c63ff\] {
    --tw-text-opacity: 1;
    color: rgba(108, 99, 255, var(--tw-text-opacity));
}
}
</style>
