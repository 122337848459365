<template>
    <div class="relative z-50">
      <nav class="backdrop-blur-md nav  fixed w-full z-50 ">
            <div class="nav-1 mx-auto flex justify-between items-center">
              <div class="nav-1-1  font-bold font-bubblegum-sans">
                <i class="nav-1-1-1 fas animate-bounce"></i>
                BatBaby
              </div>
             <button onClick="setIsMenuOpen" class="nav-button">
                 <i class="fas fa-bars" style="font-size: 1.25rem;line-height: 1.75rem;">三</i>
             </button>
  
             <div :class="`${isMenuOpen ? 'hiden' : 'block'}`">
                <div className="flex flex-col-row gap-6 text-[#fff] font-varela-round">
                  <a
                    href="/"
                    className="nav-link hover:text-[#ff2e63] transition-colors"
                  >
                    <i className="fas  mr-2"></i>Home
                  </a>
                  <a
                    href="#"
                    className="nav-link hover:text-[#ff2e63] transition-colors"
                  >
                    <i className="fas mr-2 floating-star"></i>About
                  </a>
                  <a
                    href="#"
                    className="nav-link hover:text-[#ff2e63] transition-colors"
                  >
                    <i className="fas  mr-2"></i>Model
                  </a>
                  <a
                    href="#"
                    className="nav-link hover:text-[#ff2e63] transition-colors"
                  >
                    <i className="fas mr-2"></i>Roadmap
                  </a>
                </div>
              </div>
            </div> 
          </nav>
  
    </div>
  </template>
  
  <script>
  export default {
    name: 'Nav',
    data() {
      return {
        isMenuOpen: false,
      };
    },
    methods: {
        setIsMenuOpen(){
            this.isMenuOpen = !this.isMenuOpen;
        }   
    },
    mounted() {
     
    },
    beforeDestroy() {
     
    },
  };
  </script>
  
  <style scoped>
  .nav{
    --tw-bg-opacity: 0.4;
    background-color: rgba(26, 26, 46, var(--tw-bg-opacity));
    border-bottom-width:1;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    --tw-border-opacity: 0.2;
    border-color: rgba(108, 99, 255, var(--tw-border-opacity));
    /* color: #fff; */
    .nav-1{
        max-width:80rem;
        .nav-1-1{
            font-size: 1.5rem;
            line-height: 2rem;
            --tw-text-opacity: 1;
            color: rgba(255, 255, 255, var(--tw-text-opacity));

            .nav-1-1-1{
                margin-right: 0.5rem;
            }

        }
    }
    .nav-button{
        --tw-text-opacity: 1;
        color: rgba(255, 255, 255, var(--tw-text-opacity));
        display: none;
    }

    .font-varela-round {
        font-family: "Varela Round";
    }
    .flex-col-row {
        flex-direction: row;
    }
    .gap-6 {
        gap: 1.5rem;
    }
    .mr-2 {
        margin-right: 0.5rem;
    }
    .nav-link {
        display: flex;
        align-items: center;
    }   
   
}
  
  
  @media (max-width: 768px) {
    .nav-button{
        display: block;
    }
    .flex-col-row {
        flex-direction: column;
    }
  }
  </style>