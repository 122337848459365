<template>
    
        <section className="max-w-7xl mx-auto mb-20">
            <div className="bg-[#1a1a2e]/50 backdrop-blur-md rounded-xl p-8 border border-[#6c63ff]/20">
              <h2 className="text-4xl md:text-5xl font-bubblegum-sans text-[#6c63ff] mb-8">
                BatX Token Economy Model
                <i className="fas fa-coins ml-3 text-[#ff2e63]"></i>
              </h2>

              <div className="space-y-12">
                <div>
                  <h3 className="text-2xl text-[#ff2e63] font-bold mb-4">
                    Token Overview
                  </h3>
                  <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
                   
                      <div
                        className="bg-[#1a1a2e]/30 backdrop-blur-sm p-6 rounded-xl border border-[#6c63ff]/20"
                      >
                        <div className="flex items-center mb-3">
                
                          <h4 className="text-[#6c63ff] font-bold">
                            Token Name
                          </h4>
                        </div>
                        <p className="text-gray-300">BatX</p>
                      </div>

                      <div
                        className="bg-[#1a1a2e]/30 backdrop-blur-sm p-6 rounded-xl border border-[#6c63ff]/20"
                      >
                        <div className="flex items-center mb-3">
                
                          <h4 className="text-[#6c63ff] font-bold">
                            Blockchain
                          </h4>
                        </div>
                        <p className="text-gray-300">1 Billion (1,000,000,000 BatX)</p>
                      </div>

                      <div
                        className="bg-[#1a1a2e]/30 backdrop-blur-sm p-6 rounded-xl border border-[#6c63ff]/20"
                      >
                        <div className="flex items-center mb-3">
                
                          <h4 className="text-[#6c63ff] font-bold">
                            Total Supply
                          </h4>
                        </div>
                        <p className="text-gray-300">NFT transactions, ecosystem governance, staking rewards, GameFi interactions, and decentralized payments</p>
                      </div>
                      <div
                        className="bg-[#1a1a2e]/30 backdrop-blur-sm p-6 rounded-xl border border-[#6c63ff]/20"
                      >
                        <div className="flex items-center mb-3">
                
                          <h4 className="text-[#6c63ff] font-bold">
                            Total Supply
                          </h4>
                        </div>
                        <p className="text-gray-300">NFT transactions, ecosystem governance, staking rewards, GameFi interactions, and decentralized payments</p>
                      </div>
                 
                  </div>
                </div>

                <div>
                  <h3 className="text-2xl text-[#ff2e63] font-bold mb-4">
                    Token Utility
                  </h3>
                  <ul className="space-y-3">
                      <li  className="flex items-start">
                        <i className="fas fa-check-circle text-[#ff2e63] mt-1 mr-3"></i>
                        <span className="text-gray-300">Purchase and trade Batbaby NFTs and related derivatives.</span>
                      </li>

                      <li className="flex items-start">
                        <i className="fas fa-check-circle text-[#ff2e63] mt-1 mr-3"></i>
                        <span className="text-gray-300">Participate in DAO governance and vote on ecosystem developments.</span>
                      </li>

                      <li  className="flex items-start">
                        <i className="fas fa-check-circle text-[#ff2e63] mt-1 mr-3"></i>
                        <span className="text-gray-300">Stake BatX to earn additional rewards.</span>
                      </li>

                      <li  className="flex items-start">
                        <i className="fas fa-check-circle text-[#ff2e63] mt-1 mr-3"></i>
                        <span className="text-gray-300">Serve as the core currency for in-game and metaverse interactions.</span>
                      </li>

                      <li  className="flex items-start">
                        <i className="fas fa-check-circle text-[#ff2e63] mt-1 mr-3"></i>
                        <span className="text-gray-300">Future use in brand collaborations and real-world product redemption.</span>
                      </li>
                   
                  </ul>
                </div>

                <div>
                  <h3 className="text-2xl text-[#ff2e63] font-bold mb-4">
                    Token Distribution
                  </h3>
                  <div className="grid md:grid-cols-2 lg:grid-cols-5 gap-4">
                      <div
                        className="bg-[#1a1a2e]/30 backdrop-blur-sm p-4 rounded-xl border border-[#6c63ff]/20 text-center"
                      >
                        <div className="flex flex-col items-center">
                          <i
                            className="fas fa-${item.icon} text-[#ff2e63] text-2xl mb-2"
                          ></i>
                          <h4 className="text-[#6c63ff] font-bold mb-1">
                            Ecosystem Fund
                          </h4>
                          <p className="text-gray-300 text-xl font-bold">
                            40%
                          </p>
                        </div>
                      </div>

                      <div
                        className="bg-[#1a1a2e]/30 backdrop-blur-sm p-4 rounded-xl border border-[#6c63ff]/20 text-center"
                      >
                        <div className="flex flex-col items-center">
                          <i
                            className="fas fa-${item.icon} text-[#ff2e63] text-2xl mb-2"
                          ></i>
                          <h4 className="text-[#6c63ff] font-bold mb-1">
                            Team & Advisors
                          </h4>
                          <p className="text-gray-300 text-xl font-bold">
                            20%
                          </p>
                        </div>
                      </div>

                      <div
                        className="bg-[#1a1a2e]/30 backdrop-blur-sm p-4 rounded-xl border border-[#6c63ff]/20 text-center"
                      >
                        <div className="flex flex-col items-center">
                          <i
                            className="fas fa-${item.icon} text-[#ff2e63] text-2xl mb-2"
                          ></i>
                          <h4 className="text-[#6c63ff] font-bold mb-1">
                            Initial Public Sale
                          </h4>
                          <p className="text-gray-300 text-xl font-bold">
                            15%
                          </p>
                        </div>
                      </div>

                      <div
                        className="bg-[#1a1a2e]/30 backdrop-blur-sm p-4 rounded-xl border border-[#6c63ff]/20 text-center"
                      >
                        <div className="flex flex-col items-center">
                          <i
                            className="fas fa-${item.icon} text-[#ff2e63] text-2xl mb-2"
                          ></i>
                          <h4 className="text-[#6c63ff] font-bold mb-1">
                            Liquidity Pool
                          </h4>
                          <p className="text-gray-300 text-xl font-bold">
                            10%
                          </p>
                        </div>
                      </div>
                  </div>
                </div>

                <div>
                  <h3 className="text-2xl text-[#ff2e63] font-bold mb-4">
                    Acquisition Methods
                  </h3>
                  <div className="grid md:grid-cols-2 gap-6">
                    
                      <div
                        className="bg-[#1a1a2e]/30 backdrop-blur-sm p-6 rounded-xl border border-[#6c63ff]/20 flex items-start"
                      >
                        <i
                          className="fas fa-${item.icon} text-[#ff2e63] text-xl mt-1 mr-3"
                        ></i>
                        <p className="text-gray-300">Receive BatX airdrops by purchasing Genesis Collection NFTs.</p>
                      </div>

                      <div
                        className="bg-[#1a1a2e]/30 backdrop-blur-sm p-6 rounded-xl border border-[#6c63ff]/20 flex items-start"
                      >
                        <i
                          className="fas fa-${item.icon} text-[#ff2e63] text-xl mt-1 mr-3"
                        ></i>
                        <p className="text-gray-300">Receive BatX airdrops by purchasing Genesis Collection NFTs.</p>
                      </div>

                      <div
                        className="bg-[#1a1a2e]/30 backdrop-blur-sm p-6 rounded-xl border border-[#6c63ff]/20 flex items-start"
                      >
                        <i
                          className="fas fa-${item.icon} text-[#ff2e63] text-xl mt-1 mr-3"
                        ></i>
                        <p className="text-gray-300">Receive BatX airdrops by purchasing Genesis Collection NFTs.</p>
                      </div>

                      <div
                        className="bg-[#1a1a2e]/30 backdrop-blur-sm p-6 rounded-xl border border-[#6c63ff]/20 flex items-start"
                      >
                        <i
                          className="fas fa-${item.icon} text-[#ff2e63] text-xl mt-1 mr-3"
                        ></i>
                        <p className="text-gray-300">Receive BatX airdrops by purchasing Genesis Collection NFTs.</p>
                      </div>
        
                  </div>
                </div>
              </div>
            </div>
          </section>
  </template>
  
  <script>
  export default {
    name: 'Header',
    data() {
      return {
       
      };
    },
    methods: {
      
    },
    mounted() {
     
    },
    beforeDestroy() {
     
    },
  };
  </script>
  
  <style scoped>
    .about{
      
    }
   
  
  
  @media (max-width: 768px) {
    
  }
  </style>